import { useState, useRef, useEffect } from 'react';
import {
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Checkbox,
  Stack,
  CircularProgress,
} from '@mui/material';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/tr';

import { createUseStyles } from 'react-jss';
import { useSnackbar } from 'notistack';
import { t2 } from '../../utils/Language';

import { FaCommentMedical } from 'react-icons/fa';

import { publicPatientURL } from '../../config';
import {
  axiosGet,
  axiosPatch,
  axiosPost,
  errorHandler,
  INQUIRY_STATE_ACTIVE,
  INQUIRY_STATE_FINALIZED,
} from '../../utils/utils';
import InquiryMessageView from '../../components/Inquiry/InquiryMessageView';
import MediaUploadPane from '../../components/Inquiry/MediaUploadPane';
import PhoneInput from '../../components/PhoneInput';

const useStyles = createUseStyles({
  container: {
    backgroundColor: 'white',
    borderRadius: 10,
    paddingTop: 24,
    paddingLeft: 24,
    paddingRight: 24,
    width: '100%',
    height: '100%',
    '@media (max-width: 720px)': {
      padding: 0,
    },
  },
  headerContainer: {
    backgroundColor: 'cornflowerblue',
    padding: 4,
    borderRadius: 10,
  },
  headerText: {
    color: 'white',
    fontSize: 22,
    marginRight: 20,
  },
  flexContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 10, // Form elemanları arasındaki boşluk
    '@media (max-width: 1320px)': {
      flexDirection: 'column', // Daha küçük ekranlarda dikey düzen
    },
  },
  flexItem: {
    flex: 1,
    minWidth: 0, // Flex elemanların minimum genişliğini sıfırlar
    margin: 5,
    '@media (max-width: 1320px)': {
      flexDirection: 'column', // Daha küçük ekranlarda dikey düzen
      flex: 1,
      alignItems: 'center',
      alignContent: 'center',
    },
  },
  shortInputDivLeft: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'flex-start',
    height: 60,
    margin: 15,
    '@media (max-width: 1320px)': {
      flexDirection: 'row', // Daha küçük ekranlarda dikey düzen
      flex: 1,
      justifyContent: 'center',
      marginLeft: 0,
    },
  },
  shortInputDivRight: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'flex-start',
    height: 60,
    margin: 15,
    '@media (max-width: 1320px)': {
      flexDirection: 'row', // Daha küçük ekranlarda dikey düzen
      flex: 1,
      justifyContent: 'flex-start',
      marginLeft: 0,
    },
  },
  longInputDiv: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    margin: 16,
    flex: 1,
    '@media (max-width: 1320px)': {
      marginLeft: 5,
      marginRight: 0,
    },
    '@media (max-width: 720px)': {
      marginLeft: 5,
      marginRight: 0,
    },
  },
  title: {
    fontSize: 18,
    marginRight: 24,
    minWidth: 130,
    '@media (max-width: 720px)': {
      display: 'none',
    },
  },
  field: {
    width: 280,
    '@media (max-width: 720px)': {
      paddingRight: 20,
    },
  },
  error: {
    color: '#d32f2f',
  },
  phoneInput: {
    marginLeft: 0,
    borderBottom: '1px solid rgba(0, 0, 0, 0.42) !important',
    borderTop: 'none !important',
    borderLeft: 'none !important',
    borderRight: 'none !important',
    borderRadius: '0 !important',
    width: '280px !important',
  },
  newMessageCard: {
    backgroundColor: 'whitesmoke',
    borderRadius: 10,
    marginTop: 24,
    marginBottom: 24,
    padding: '16px 8px 16px',
  },
  mediaUploadContainer: { width: '100%', marginTop: 16 },
});

function InquiryDetails({ inquiryId, doctorId, onLoading }) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [gender, setGender] = useState('null');
  const [birthdate, setBirthdate] = useState(null); // keep in mind that this is datejs object
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('90');
  const [address, setAddress] = useState('');
  const [mediaList, setMediaList] = useState([]);
  const [messageList, setMessageList] = useState([]);
  const [tckn, setTckn] = useState('');
  const messageRef = useRef();
  const inquiryState = useRef();
  const [nameError, setNameError] = useState(false);
  const [surnameError, setSurnameError] = useState(false);
  const [mediaReady, setMediaReady] = useState(true);
  const mediaUploader = useRef(null);
  const willSendNotification = useRef(false);
  const waitingEvent = useRef(false);

  useEffect(() => {
    setPhone(localStorage.getItem('phoneNumber'));
    setName(localStorage.getItem('name'));
    setSurname(localStorage.getItem('surname'));
    setGender(localStorage.getItem('gender'));
    setEmail(localStorage.getItem('email'));
    setAddress(localStorage.getItem('address'));
    setBirthdate(dayjs(Number(localStorage.getItem('birthdate'))));

    getInquiryData();

    const logoutEventListener = (e) => {
      e.preventDefault();
      waitingEvent.current = true;
      notifyInquiry();
    };

    document.addEventListener('willLogout', logoutEventListener);

    return () => {
      notifyInquiry();

      document.removeEventListener('willLogout', logoutEventListener);
    };
  }, []);

  const notifyInquiry = () => {
    if (willSendNotification.current) {
      willSendNotification.current = false;

      axiosPost(
        `${publicPatientURL}/inquiries/${inquiryId}/notify`,
        {
          isNew: false,
        },
        true,
      )
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          waitingEvent.current = false;
          document.dispatchEvent(new CustomEvent('canLogout'));
        });
    } else {
      if (waitingEvent.current) {
        waitingEvent.current = false;
        document.dispatchEvent(new CustomEvent('canLogout'));
      }
    }
  };

  const getInquiryData = async () => {
    onLoading(true);

    await axiosGet(`${publicPatientURL}/inquiries/${inquiryId}`, true)
      .then(({ data }) => {
        setMessageList(data.messageList);
        setMediaList(data.mediaList);

        inquiryState.current = data.inquiryState;
      })
      .catch((err) => enqueueSnackbar(err.message, { variant: 'error' }))
      .finally(() => onLoading(false));
  };

  const handleNameChanged = (e) => {
    if (e?.target?.value?.length > 0) setNameError(false);
    setName(e?.target?.value);
  };

  const handleSurnameChanged = (e) => {
    if (e?.target?.value?.length > 0) setSurnameError(false);
    setSurname(e?.target?.value);
  };

  const handleTcknChanged = (e) => {
    setTckn(e?.target?.value);
  };

  const handleGenderChanged = (e) => {
    setGender(e?.target?.value);
  };

  const handleBirthdateChange = (date) => {
    setBirthdate(date);
  };

  const handleEmailChanged = (e) => {
    setEmail(e?.target?.value);
  };

  const handleAddressChanged = (e) => {
    setAddress(e?.target?.value);
  };

  const handleSendButtonClicked = async () => {
    let error = false;
    if (name?.length === 0) {
      setNameError(true);
      error = true;
    }

    if (surname?.length === 0) {
      setSurnameError(true);
      error = true;
    }

    if (error) {
      enqueueSnackbar(t2.fillRequiredFields, { variant: 'warning' });
      return;
    }

    onLoading(true);

    try {
      await updateInquiry();

      const uploadedMediaCount = await mediaUploader.current.upload(inquiryId);

      if (messageRef.current.value.length > 0 || uploadedMediaCount > 0) {
        willSendNotification.current = true;
      }

      localStorage.setItem('name', name);
      localStorage.setItem('surname', surname);
      localStorage.setItem('gender', gender);
      localStorage.setItem('birthdate', new Date(birthdate).getTime());
      localStorage.setItem('email', email);
      localStorage.setItem('address', address);

      enqueueSnackbar(t2.inquiryRequestUpdated, { variant: 'success' });

      await getInquiryData();
      messageRef.current.value = '';
    } catch (error) {
      enqueueSnackbar(errorHandler(error), { variant: 'error' });
    }

    onLoading(false);
  };

  const updateInquiry = async () => {
    await axiosPatch(
      `${publicPatientURL}/inquiries/${inquiryId}`,
      {
        name,
        surname,
        phoneNumber: phone,
        tckn,
        gender: gender === 'null' ? null : gender,
        email,
        birthdate: new Date(birthdate).getTime(),
        address,
        message: messageRef.current.value,
        doctorId,
      },
      true,
    );
  };

  return (
    <>
      <div className={classes.container}>
        <div className={classes.headerContainer}>
          <Stack
            flexDirection='row'
            justifyContent='center'
            alignItems='center'
            className={classes.headerContent}>
            <Typography className={classes.headerText}>
              {t2.inquiryRequest}
            </Typography>
            <FaCommentMedical size={28} color='white' />
          </Stack>
        </div>

        <Stack
          flexDirection={'row'}
          justifyContent={'flex-end'}
          style={{ marginTop: 8, marginRight: 8 }}>
          <Typography variant='caption' className={classes.error}>
            {t2.fieldsRequired}
          </Typography>
        </Stack>

        <div className={classes.flexContainer}>
          <div className={classes.flexItem}>
            <div className={classes.shortInputDivLeft} style={{ marginTop: 0 }}>
              <Typography
                className={`${classes.title} ${
                  nameError ? classes.error : ''
                }`}>
                {`${t2.yourName} *:`}
              </Typography>
              <TextField
                style={{ flex: 1 }}
                className={classes.field}
                variant='standard'
                error={nameError}
                value={name}
                onChange={handleNameChanged}
                label={t2.yourName}
                inputProps={{ form: { autocomplete: 'off' } }}
                autoFocus
              />
            </div>
            <div className={classes.shortInputDivLeft}>
              <Typography
                className={`${classes.title} ${
                  surnameError ? classes.error : ''
                }`}>
                {`${t2.yourSurname} *:`}
              </Typography>
              <TextField
                style={{ flex: 1 }}
                className={classes.field}
                variant='standard'
                error={surnameError}
                value={surname}
                onChange={handleSurnameChanged}
                label={t2.yourSurname}
                inputProps={{ form: { autocomplete: 'off' } }}
              />
            </div>
            <div className={classes.shortInputDivLeft} style={{ marginTop: 0 }}>
              <Typography className={`${classes.title} `}>
                {`${t2.yourTckn} :`}
              </Typography>
              <TextField
                style={{ flex: 1 }}
                className={classes.field}
                variant='standard'
                value={tckn}
                onChange={handleTcknChanged}
                label={t2.yourTckn}
                inputProps={{ form: { autocomplete: 'off' } }}
              />
            </div>
            <div
              className={classes.shortInputDivLeft}
              style={{ marginTop: 30 }}>
              <Typography className={classes.title} gutterBottom>
                {`${t2.phone} *:`}
              </Typography>
              <PhoneInput
                disabled
                value={phone}
                inputClass={classes.phoneInput}
              />
            </div>
          </div>

          <div className={classes.flexItem}>
            <div
              className={classes.shortInputDivRight}
              style={{ marginTop: 0 }}>
              <Typography
                className={classes.title}>{`${t2.birthdate}:`}</Typography>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale='tr'>
                <DesktopDatePicker
                  className={classes.field}
                  disableMaskedInput
                  label={t2.birthdate}
                  value={birthdate}
                  maxDate={dayjs()}
                  views={['month', 'year', 'day']}
                  onChange={handleBirthdateChange}
                  slotProps={{ field: { variant: 'standard' } }}
                />
              </LocalizationProvider>
            </div>
            <div className={classes.shortInputDivRight}>
              <Typography className={classes.title} gutterBottom>
                {`${t2.gender}:`}
              </Typography>
              <FormControl className={classes.field}>
                <InputLabel
                  style={{ transform: 'translate(0px, -9px) scale(0.75)' }}>
                  {t2.gender}
                </InputLabel>
                <Select
                  variant='standard'
                  value={gender}
                  onChange={handleGenderChanged}>
                  <MenuItem value={'null'}>{t2.notSpecified}</MenuItem>
                  <MenuItem value={'male'}>{t2.male}</MenuItem>
                  <MenuItem value={'female'}>{t2.female}</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className={classes.shortInputDivRight}>
              <Typography className={classes.title}>
                {`${t2.email}:`}
              </Typography>
              <TextField
                style={{ flex: 1 }}
                className={classes.field}
                variant='standard'
                value={email}
                onChange={handleEmailChanged}
                label={t2.email}
                inputProps={{ form: { autocomplete: 'off' } }}
              />
            </div>
            <div
              className={classes.shortInputDivRight}
              style={{ marginTop: 30 }}>
              <Typography
                className={classes.title}>{`${t2.address}:`}</Typography>
              <TextField
                className={classes.field}
                style={{ flex: 1, paddingRight: 5 }}
                variant='standard'
                multiline
                value={address}
                onChange={handleAddressChanged}
                label={t2.address}
                inputProps={{ form: { autocomplete: 'off' } }}
              />
            </div>
          </div>
        </div>

        {(messageList.length > 0 || mediaList.length > 0) && (
          <div sx={{ mt: 1 }}>
            <InquiryMessageView
              inquiryId={inquiryId}
              messageList={messageList}
              mediaList={mediaList}
              onUpdateRequired={getInquiryData}
              onLoading={onLoading}
            />
          </div>
        )}

        {inquiryState.current === INQUIRY_STATE_FINALIZED && (
          <Stack
            justifyContent={'center'}
            alignItems={'center'}
            style={{ marginTop: 16 }}>
            <Typography>{t2.inquiryFinalized}</Typography>
          </Stack>
        )}

        {inquiryState.current === INQUIRY_STATE_ACTIVE && (
          <>
            <div className={classes.newMessageCard}>
              <div
                className={classes.longInputDiv}
                style={{ margin: '16px 20px' }}>
                <TextField
                  style={{ flex: 1 }}
                  variant='standard'
                  multiline
                  rows={3}
                  inputRef={messageRef}
                  label={`${t2.yourResponse}...`}
                  inputProps={{ form: { autocomplete: 'off' } }}
                />
              </div>
              <div className={classes.mediaUploadContainer}>
                <MediaUploadPane
                  ref={mediaUploader}
                  doctorId={doctorId}
                  onReady={(ready) => setMediaReady(ready)}
                />
              </div>
            </div>
            <Stack
              flexDirection='row'
              justifyContent='flex-end'
              alignItems='center'
              sx={{
                '@media (max-width: 720px)': {
                  flexDirection: 'column',
                },
              }}>
              <Button
                variant='contained'
                disabled={!mediaReady}
                onClick={handleSendButtonClicked}
                size='large'
                sx={{ width: 200, '@media (max-width: 720px)': { mt: 2 } }} // Küçük ekranlarda üst marj ekliyoruz
              >
                {t2.save}
              </Button>
            </Stack>
          </>
        )}
      </div>
    </>
  );
}

export default InquiryDetails;
